.footer-container{
    display: flex;
    overflow-wrap: break-word;
    flex-wrap: wrap;

    /* background-color: #D3EAFF; */
    border-top: 1px solid #DBDBDB;
    
    width: 100%;
    max-width: 1440px;
    margin: 1.5rem auto 0 auto;
    padding-top: 1.5rem;

    background-position: center;
    background-size: cover;
}
.footer-container-section{
    padding: 1rem;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}
.footer-container-left{
    width: 30%;
}
.footer-container-left-logo > img{
    width: 60%;
}
.footer-container-left-socials{
    display: flex;
}
.footer-socials-box{
    /* border: 2px solid #DBDBDB; */
    margin: .2rem;
    padding: .2rem;
}
.footer-socials-box > a {
  color: #FFFFFF;
}
.footer-socials-box > a > svg{
    font-size: 1.5rem;
}

.footer-container-mid{
    display: flex;
    width: 70%;
}
.footer-container-mid{
    padding-left: 0;
}
.footer-mid-service-content{
    display: flex;
}
.footer-container-mid-left{
    width: 30%;
}
.footer-container-mid-right{
    width: 70%;
}
.footer-mid-title{
    font-weight: bold;
    margin-bottom: .75rem;
}
.footer-mid-nav-content{
    margin: .2rem;
}
.footer-mid-service-left, .footer-mid-service-right{
    width: 50%;
}
.footer-mid-company-nav-text, .footer-mid-service-nav-text, .footer-mid-title{
  color: #FFFFFF;
}
.footer-mid-company-nav-text:hover, .footer-mid-service-nav-text:hover{
  color: #006BFF;
  transition: .5s;
}

.footer-container-bottom{
    padding: 1rem;
    color: #FFFFFF;
}

/* MDN */
@media (max-width: 869px) {
    .footer-container-mid-left{
        width: 20%;
    }
    .footer-container-mid-right{
        width: 80%;
    }
}
@media (max-width: 764px) {
    .footer-container-left, .footer-container-mid{
        width: 100%;
    }
    .footer-container-mid{
        padding-left: 1.5rem;
    }
    .footer-container-left-logo{
        width: 50%;
    }
}
@media (max-width: 600px) {
    .footer-container-mid, .footer-mid-service-content{
        flex-wrap: wrap;
    }
    .footer-container-mid-left, .footer-container-mid-right, .footer-mid-service-left, .footer-mid-service-right{
        width: 100%;
    }
    .footer-mid-services{
        margin-top: 2rem;
    }
    .footer-container-left{
        padding-bottom: 1rem;
    }
}