.our-services-container{
    min-height: 70vh;
    max-width: 1040px;
    margin: 4rem auto;
    padding-bottom: 3rem;
}
.our-services-content-container{
    margin: auto;
    padding: 2.5rem 3rem;
    max-width: 1028px;
}
.our-services-content-img{
    height: 40vh;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.our-services-content-text{
    /* color: #FFFFFF; */
}
.our-services-content-text-title{
    font-size: 2.2rem;
    margin: 1rem 0;
}
.our-services-content-text-desc{
    font-size: 1.05rem;
    text-align: justify;
    letter-spacing: 0.1em;
}
.our-services-content-text-button{
    margin-top: 2rem;
}
.our-services-button{
    background: #C8E4FF;
    color: #0a55e1;

    font-size: 1rem;
}
.our-services-button:hover{
    background: #FFFFFF;
    color: #003498;
    transition: .5s;
}
.our-services-container-gradient{
    height: 5rem;
    padding: 0;

    max-width: 1040px;
    margin: -1px auto 0 auto;
    transform: rotate(180deg);

    background-image: #2772E1;
    background-image: linear-gradient(270deg, #FFFFFF -5%, #2772E1 5rem);
    background-image: -webkit-linear-gradient(270deg, #FFFFFF 0%, #2772E1 5rem);
}
.our-services-navbar{
    display: flex;

    max-width: 1040px;
    margin: 4rem auto .5rem auto;
}
.our-services-navbar-button{
    min-width: 20%;
    display: table;

    padding: .5rem;
    text-align: center;

    background: #F0F0F0;
    border-bottom: 3px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;

    opacity: 50%;
}
.our-services-navbar-button-selected, .our-services-navbar-button:hover{
    background: #FFFFFF;
    opacity: 100%;

    transition: 0.5s;
    cursor: pointer;
}
.our-services-navbar-button-selected{
    border-bottom: 3px solid #000000;
}
.our-services-navbar-button-title{
    color: #297FFF;
    font-weight: bold;
    font-size: .9rem;

    display: table-cell;
    vertical-align: middle;
}
.our-services-navbar-content{
  display: flex;
  overflow: auto;
  max-width: 1040px;
  scroll-behavior: smooth;
}
.our-services-navbar-left, .our-services-navbar-right{ 
  position: relative;
  min-width: 3%;

  opacity: 50%;
  background: #F0F0F0;
  border-bottom: 3px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px 0px 0px 5px;;
}
.our-services-navbar-right{
  border-radius: 0px 5px 5px 0px;
}
.our-services-navbar-nav-button{
  border:       solid currentColor;
  border-width: 0 .075em .075em 0;
  display:      inline-block;
  padding:      .20em;

  position: absolute;
  top: 50%;
  left: 60%;

  cursor: pointer;
  /* animation: animateArrow 2s infinite; */
}
.our-services-navbar-nav-button-right{
  left: 30%;
}
.our-services-navbar-nav-button-left:hover{
  animation: animateArrowLeft 1.5s infinite;
}
.our-services-navbar-nav-button-right:hover{
  animation: animateArrowRight 1.5s infinite;
}
.our-services-navbar-nav-span{
  border:       solid currentColor;
  border-width: 0 .1em .1em 0;
  display:      inline-block;
  padding:      .20em;

  position: absolute;
  top: 50%;
  left: 50%;

  cursor: pointer;
}
.our-services-navbar-nav-button-left{
  transform: translate(-50%, -50%) rotate(135deg);
}
.our-services-navbar-nav-button-right{
  transform: translate(-50%, -50%) rotate(-45deg);
}

/* animation */
.our-services-navbar-nav-button span:nth-child(2) {
  animation-delay: -0.2s;
}
.our-services-navbar-nav-button span:nth-child(3) {
  animation-delay: -0.4s;
}
@keyframes animateArrowLeft {
  0% {
      opacity: 0;
      transform: translate(-50%, -50%) rotate(135deg);
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
      transform: translate(-80%, -50%) rotate(135deg);
  }
}
@keyframes animateArrowRight {
  0% {
      opacity: 0;
      transform: translate(-80%, -50%) rotate(-45deg);
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
      transform: translate(-50%, -50%) rotate(-45deg);
  }
}

/* MDN */
@media (max-width: 1074px) {
    .our-services-navbar-button-title{
        font-size: .75rem;
    }
}
@media (max-width: 835px) {
    .our-services-navbar-button{
        min-width: 27%;
    }
}
@media (max-width: 600px) {
    .our-services-navbar-left, .our-services-navbar-right, .our-services-navbar-nav-button{
      display: none;
    }
    .our-services-navbar{
      width: 100%;
    }
    .our-services-navbar-button{
        padding: 0.25rem;
    }
    .our-services-navbar-button-title{
        font-size: .65rem;
    }
    .our-services-content-container{
        padding: .15rem 2rem;
    }
    .our-services-content-text-title{
        font-size: 2rem;
    }
    .our-services-content-text-desc{
        font-size: 1rem;
    }
}