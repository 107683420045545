.custom-button{
    min-width: 5.5rem;
    padding: .5rem 1rem;
    border: none;

    background-color: #4361FF;
    border-radius: .5rem;

    color: #FFFFFF;
    font-weight: bold;
    cursor: pointer;
}
.custom-button:hover{
    background-color: #FFFFFF;
    color: #4361FF;
    transition: .5s;
}