.about-us-top-container{
    text-align: center;
    min-height: 60vh;
    margin: 2rem auto 0 auto;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.about-us-container{
    position: relative;

    min-height: 70vh;
    max-width: 1024px;
    margin: auto;
    padding: 5rem;
}
.about-us-left-bar{
    position: absolute;
    top: 20%;
    left: -5%;

    width: 5rem;
    height: 100vh;
    background: #D3EAFF;
}
.about-us-description-text{
    text-align: justify;
    letter-spacing: 0.05em;
}
.about-us-vision-mission{
    margin-top: 5rem;
}
.about-us-vision-mission-content{
    display: flex;
    text-align: justify;
    letter-spacing: 0.05em;
    flex-wrap: wrap;
}
.about-us-vision-mission-img-container{
    width: 30%;
}
.about-us-vision-img{
    width: 100%;
}
.about-us-vision-mission-text-container{
    width: 60%;
    display: table;

    margin: 5%;
}
.about-us-vision-mission-text{
    display: table-cell;
    vertical-align: middle;
}
.about-us-mission{
    text-align: right;
}
@media (min-height: 1200px) {
    .about-us-left-bar{
        height: 70vh;
    }
}
@media (min-height: 1500px) {
    .about-us-left-bar{
        height: 50vh;
    }
}
@media (max-width: 700px) {
    .about-us-vision-mission-img-container{
        width: 70%;
        margin: auto;
        order: 1;
    }
    .about-us-vision-mission-text-container{
        width: 100%;
        order: 2;
    }
}
@media (max-width: 580px) {
    .about-us-container{
        padding: 2rem;
    }
    .about-us-left-bar{
        width: 3rem;
    }
}
@media (max-width: 450px) {
    .about-us-left-bar{
        display: none;
    }
}