.home-container{
    max-width: 1920px;
    margin: 2rem auto 0 auto;
}

/* Intro Message */
.home-intro-message-container{
    margin: 3rem auto;
    padding: 2.5rem;
    max-width: 1024px;

    text-align: justify;
}
.home-intro-message-emp{
    font-weight: bold;
    color: #297FFF;
}
.home-intro-message-top{
    font-size: 1.25rem;
    letter-spacing: .05rem;
}
.home-intro-message-bottom{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    margin-top: 4rem;
    gap: 3rem 0;
}
.home-intro-box{
    width: 33.33%;

    text-align: center;
}
.home-intro-box-num{
    margin-bottom: 1rem;

    font-size: 2.2rem;
}
.home-intro-box-desc{
    font-size: 1.1rem;
}
/* End of Intro Message */

/* Services */
.home-services-container{
    background-color: #2772E1;
}
.home-services-container-gradient{
    height: 5rem;
    padding: 0;
    margin-bottom: -1px;

    background-image: #2772E1;
    background-image: linear-gradient(270deg, #FFFFFF -5%, #2772E1 5rem);
    background-image: -moz-linear-gradient(270deg, #FFFFFF -5%, #2772E1 5rem);
    background-image: -webkit-linear-gradient(270deg, #FFFFFF 0%, #2772E1 5rem);
}
.home-services-container-gradient-bottom{
    margin-top: -1px;
    margin-bottom: 0;

    transform: rotate(180deg);
}
.home-services-box-container{
    max-width: 1024px;
    padding: 0 2rem;
    margin: 0 auto;

    color: #FFFFFF;
}
.home-services-box-title{
    font-size: 2rem;
    font-weight: bold;
}
.home-services-box-desc{
    margin: 1rem 0 3rem 0;
    font-size: 1.1rem;
}
/* End of Services */

/* Corporate Value */
.home-corporate-value-container{
    max-width: 1024px;
    margin: 5rem auto;
}
.home-corporate-value-title{
    width: 50%;
    margin: auto;
    text-align: center;

    font-weight: bold;
    font-size: 2.5rem;
}
.home-corporate-value-title-line{
    border: 4px solid #8EE6FF;
}
.home-corporate-value-circle-container{
    display: flex;
    margin-top: 3rem;

    flex-wrap: wrap;
    justify-content: center;
}
.home-corporate-value-circle-box-container{
    width: 30%;
    margin: 0 1%;
}
.home-corporate-value-circle-box-img{
    width: 100%;
    padding-top: 100%;
    border-radius: 100%;
    
    background-size: cover;
    background-position: center;
}
.home-corporate-value-circle-box-title{
    margin-top: 1rem;
    text-align: center;

    font-weight: bold;
    font-size: 1.65rem;
}
.home-corporate-value-circle-box-desc{
    margin: 1rem;

    text-align: center;
    letter-spacing: 0.05em;
    font-size: 1.1rem;
}
/* End of Corporate Value */

/* Our Clients */
.home-our-clients-world-map-container{
    width: 100%;

    background: #2772E1;
}
.home-our-clients-world-map-content{
  max-width: 1024px;
  margin: auto;
}
.home-our-clients-world-map-content-title{
    padding: 1.5rem;

    font-size: 2.5rem;
    font-weight: bold;
    color: #FFFFFF;
}
.home-our-clients-world-map-img{
    width: 100%;
}
/* End of Our Clients */

/* MDN */
@media (max-width: 768px) {
    .home-corporate-value-title{
        width: 90%;
    }
    .home-corporate-value-circle-box-container{
        width: 60%;
        margin: 5%;
    }
    .home-our-clients-world-map-container{
        width: 100%;
        margin: 3rem 0;
    }
}
@media (max-width: 600px) {
    .home-corporate-value-circle-box-container{
        width: 80%;
    }
    .home-intro-box{
        width: 50%;
    }
    .home-intro-message-container{
        padding: 1rem;
    }
}