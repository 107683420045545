.navbar-container{
    width: 100%;
    padding: .1rem;

    text-align: left;
    display: flex;
    z-index: 99;
    top: 0;

    position: fixed;
    background-color: #FFFFFF;
    border-bottom: 0.1rem solid rgb(219, 219, 219);
}
.navbar-logo{
    max-width:100%;
    max-height:3rem;
    margin-left: .5rem;
}
.navbar-content, .navbar-content-logo{
    width: 20%;

    margin: auto;
    display: flex;

    font-weight: bold;
}
.navbar-content-text{
    opacity: 50%;
}
.navbar-content-text:hover{
    border-bottom: 0.1rem solid #000000;
    margin-bottom: -0.1rem;
}
.navbar-dropdown {
  float: left;
  overflow: hidden;
}
.navbar-dropdown-icon {
  font-size: .65rem;
}
.navbar-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.navbar-dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 0.75rem;
  font-weight: normal;
}
.navbar-dropdown-content a:hover {
  background-color: #ddd;
}
.navbar-content-our-services:hover .navbar-dropdown-content {
  display: block;
}
/* Components */
a{
    text-decoration: none;
    color: #000000;
}
h4{
    margin: auto;
    padding: 0;
}


/* Codepen */
.nav-main {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: -100%;
    position: fixed;
    transition: left 0s calc(var(--td) * 2), transform 0s calc(var(--td) * 2);
    width: 100%;
    z-index: 1021;
    top: 0;
}
.nav-main::before, .nav-main::after {
    content: "";
    height: 51%;
    left: 0;
    position: absolute;
    transform: translateX(-110%);
    transform-origin: 0 50%;
    transition: transform calc(var(--td) * 2) var(--td) var(--te);
    width: 100%;
    z-index: -100;
    background: #FFFFFF;
}
.nav-main::before {
    top: 0;
}
.nav-main::after {
    bottom: 0;
}
.nav-main .menu__item {
    opacity: 0;
    transform: translateX(-1rem);
    transition: opacity var(--td) var(--te), transform var(--td) var(--te);
}
[id=main-navigation-toggle] {
    opacity: 0;
    position: fixed;
    top: -100%;
}
[id=main-navigation-toggle] ~ label {
    cursor: pointer;
    position: fixed;
    right: 1rem;
    top: 0.2rem;
    z-index: 999999999;
}
[id=main-navigation-toggle] ~ label span {
    display: none;
    height: 2rem;
    padding: 0.5rem;
    position: relative;
    transition: transform calc(var(--td) * 3) var(--te);
    width: 1.5rem;
}
[id=main-navigation-toggle] ~ label span::before, [id=main-navigation-toggle] ~ label span::after {
    background-color: #000000;
    bottom: 0;
    content: "";
    height: 4px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    transition: transform calc(var(--td) * 3) var(--te);
    top: 0;
    width: calc(100% - 1rem);
    
    transition: .5s;
}
[id=main-navigation-toggle] ~ label span::before {
    transform: rotate(0) translateY(-100%);
}
[id=main-navigation-toggle] ~ label span::after {
    transform: rotate(0) translateY(100%);
}

[id=main-navigation-toggle]:checked ~ label span {
    transform: rotate(1turn);
}
[id=main-navigation-toggle]:checked ~ label span::before {
    transform: rotate(45deg);
}
[id=main-navigation-toggle]:checked ~ label span::after {
    transform: rotate(-45deg);
}

[id=main-navigation-toggle]:checked ~ .nav-main {
    left: 0;
    transition: transform 0s;
}
[id=main-navigation-toggle]:checked ~ .nav-main::before, [id=main-navigation-toggle]:checked ~ .nav-main::after {
    transform: translateX(0);
    transition-delay: 0s;
    
    transition: 1s;
}
[id=main-navigation-toggle]:checked ~ .nav-main::after {
    transition-delay: calc(var(--td) / 2);
}
[id=main-navigation-toggle]:checked ~ .nav-main .menu__item {
    opacity: 1;
    transform: translateX(0);
    transition: 2s;
}
[id=main-navigation-toggle]:checked ~ .nav-main .menu__item:nth-child(1) {
    transition-delay: calc(var(--td) * 2 * (1 * 0.25));
    z-index: -1;
}
[id=main-navigation-toggle]:checked ~ .nav-main .menu__item:nth-child(2) {
    transition-delay: calc(var(--td) * 2 * (2 * 0.25));
    z-index: -2;
}
[id=main-navigation-toggle]:checked ~ .nav-main .menu__item:nth-child(3) {
    transition-delay: calc(var(--td) * 2 * (3 * 0.25));
    z-index: -3;
}
[id=main-navigation-toggle]:checked ~ .nav-main .menu__item:nth-child(4) {
    transition-delay: calc(var(--td) * 2 * (4 * 0.25));
    z-index: -4;
}
[id=main-navigation-toggle]:checked ~ .nav-main .menu__item:nth-child(5) {
    transition-delay: calc(var(--td) * 2 * (5 * 0.25));
    z-index: -5;
}
.menu {
    position: relative;
    text-align: center;
    z-index: 1;

    width: 100%;
}
.menu > .menu__item {
    font-size: 8vmin;

    padding-top: .5rem;
    padding-bottom: .5rem;
}
.submenu {
    left: 0;
    opacity: 0;
    position: absolute;
    transform: translateY(-10%);
    top: 100%;
    width: 100%;
    visibility: hidden;
    z-index: 2;
}
.submenu .menu__item {
    font-family: var(--font-family-primary);
    font-size: 3.5vmin;
    width: 100%;
}
.submenu .menu__link {
    color: var(--color-secondary);
    text-shadow: 1px 1px 0 var(--color-primary), 2px 2px 0 var(--color-primary);
}
.submenu .menu__link::before, .submenu .menu__link::after {
    display: none;
}
.menu__item {
    display: block;
    position: relative;
}
.menu__item:hover .menu__link::before, .menu__item:hover .menu__link::after {
    animation: blink 1s var(--td) steps(1, end) forwards infinite;
    transform: translateX(calc(100% - 0.5rem));
    transition-duration: calc(var(--td) * 3);
}
.menu__item:hover .menu__link::after {
    transition-delay: calc(var(--td) / 2);
}
.menu__item:hover .submenu {
    opacity: 1;
    transform: translateY(0);
    transition: transform calc(var(--td) * 2) calc(var(--td) * 3) var(--te), opacity calc(var(--td) * 2) calc(var(--td) * 3) var(--te), visibility 0s calc(var(--td) * 3);
    visibility: visible;
}
.menu__link {
    color: #000000;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    padding: 0.5rem 1rem 0.125rem;
    position: relative;
    text-decoration: none;
    transition: color var(--td) var(--te), opacity var(--td) var(--te), transform var(--td) var(--te);
    z-index: 1;
}
.menu__link::before, .menu__link::after {
    content: "";
    background-color: var(--color-light);
    height: 50%;
    left: 0;
    position: absolute;
    transform: translateX(-110%);
    transform-origin: 0 50%;
    transition: transform 0s var(--te);
    width: 100%;
    z-index: -1;
}
.menu__link::before {
    top: 0;
}
.menu__link::after {
    bottom: 0;
}
.menu:not(:focus-within):not(:hover) .menu__item .menu__link {
    opacity: 1;
    transform: translate(0, 0);
}
.menu__item {
    --pull: 30%;

    text-align: left;
}
.menu__item .menu__link {
    opacity: 0.25;
    transition-duration: calc(var(--td) * 3);
    transform: translate(0, calc(var(--pull) * -1));
}
.menu__item .submenu .menu__link {
    opacity: 1;
}
.menu__item:hover > .menu__link {
    color: var(--color-secondary);
    opacity: 1;
    transform: translate(0, 0);
}
.menu__item:hover > .menu__link:hover {
    color: var(--color-light);
    transition-delay: 0s;
}
.menu__item:hover ~ .menu__item > .menu__link {
    transition-duration: calc(var(--td) * 3);
    transform: translate(0, var(--pull));
}
@keyframes blink {
50%, 100% {
    opacity: 0;
}
}
.menu-service > .menu__item {
  font-size: 6vmin;
}
/* End of Codepen settings */

/* Media */
@media(max-width: 722px){
    .navbar-logo{
        max-height: 2rem;
    }
    .navbar-content-text{
        font-size: .8rem;
    }
}
@media(max-width: 500px){
    .navbar{
        /* background: #D8BD88; */
        transition: 1s;
    }
    .navbar-content{
        display: none;
    }
    .navbar-content-logo{
        margin: 0;
        width: 100%;
        min-height: 3rem;
    }
    .navbar-logo{
        max-height: 3rem;
    }
    .toggler-container, .navigation-toggle-span{
        display: block!important;
        opacity: 1!important;
    }
}