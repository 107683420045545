.carousel-image-container{
    height: 50rem;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.carousel-image-content-container {
    max-width: 1440px;
    height: 100%;
    margin: auto;
    position: relative;
}
.carousel-image-content{
    transform: translate(0, -50%);
    top: 50%;
    margin: 1.5rem;
    position: absolute;
}
.carousel-image-content-left{
    left: 0;
    width: 50%;
    margin-left: 2rem;
}
.carousel-image-content-right{
    right: 0;
    width: 40%;
    margin-right: 2rem;
}
.carousel-image-content-intro-text {
    font-weight: bold;
    font-size: 3rem;
    color: #ffffff;
    text-align: left;
}
.carousel-image-content-intro-sub-text {
    margin-top: 1rem;

    font-size: 1.75rem;
    color: #ffffff;
    text-align: left;
    letter-spacing: 0.1rem;
}
.carousel-image-content-about-us-text, .carousel-image-content-contact-us-text{
    font-weight: bold;
    font-size: 3rem;
    color: #ffffff;
    text-align: left;
}
.carousel-image-content-about-us-sub-text {
    margin-top: 1rem;
    margin-right: 0.25rem;

    font-size: 1.15rem;
    color: #ffffff;
    text-align: justify;
}
.carousel-image-content-about-us-button, .carousel-image-content-contact-us-button, .carousel-image-content-services-button{
    margin-top: 1.5rem;

    text-align: left;
}
.carousel .slide iframe, .carousel-image-content-contact-us-map-iframe{
    width: 100%;
    height: 17rem;

    border: none;
    margin: 0;
}
.carousel-container-service{
    max-width: 1024px;
    padding: 0 2rem;
    margin: auto;
}
.carousel-image-content-services{
    top: 75%;

    margin: 0 2rem;
}
.carousel-image-content-services-text{
    font-weight: bold;
    font-size: 2rem;
    color: #FFFFFF;
}
.carousel-image-content-services-desc{
    margin-top: 1rem;
    
    font-size: 1.25rem;
    color: #FFFFFF;
}

/* Temper Carousel Lib */
.carousel .control-arrow, .carousel.carousel-slider .control-arrow{
    opacity: 100%;
}
.carousel .control-next.control-arrow:before{
    border-left: 10px solid #FFFFFF;
}
.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before{
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}
.carousel .control-prev.control-arrow:before{
    border-right: 10px solid #FFFFFF;
}
.carousel-image-content-services-button{
    text-align: center;
}

/* MDN */
@media (max-width: 780px) {
    .carousel-image-content-right {
      width: 50%;
    }
    .carousel-image-content-intro-text {
        font-size: 2.5rem;
    }
    .carousel-image-content-intro-sub-text {
        font-size: 1.5rem;
    }
}
@media (max-width: 600px) {
    .carousel-image-content-left, .carousel-image-content-right {
        width: 80%;
    }
    .carousel-container-service{
        padding: 0;
    }
}
@media (max-width: 490px) {
    .carousel-image-container-intro-text {
        font-size: 2.25rem;
    }
    .carousel-image-container-intro-sub-text {
        font-size: 1.25rem;
    }
    .carousel-image-container-about-us-sub-text {
        top: 60%;

        font-size: 1.15rem;
    }
    .carousel-image-content-services{
        /* top: 70%; */
    }
    .carousel-image-content-services-text{
        font-size: 1.75rem;
    }
    .carousel-image-content-services-desc{
        font-size: 1.15rem;
    }
}