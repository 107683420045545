.contact-us-container{
    min-height: 70vh;
    max-width: 1280px;
    margin: 3rem auto 0 auto;
}
.contact-us-title-container{
    display: flex;
    flex-wrap: wrap;
    margin-top: 7.5rem;

    width: 100%;
}
.contact-us-title-before{
    width: 5%;
}
.contact-us-title{
    display: table;
    width: 25%;
}
.contact-us-title-cell{
    display: table-cell;
    vertical-align: middle;

    font-weight: bold;
    font-size: 2rem;
    text-align: center;
}
.contact-us-title-bar{
    width: 70%;
    padding: 1.5rem 0;
    
    background-color: #D3EAFF;
}
.contact-us-maps{
    width: 100%;

    border: none;
}
.contact-us-top-container{
    margin-top: 3.5rem;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.contact-us-top-left{
    width: 50%;

    align-self: stretch;
    display: flex;
}
.contact-us-top-right{
    width: 30%;
    padding: 4rem 5%;

    display: table;
}
.contact-us-address{
    display: table-cell;
    vertical-align: middle;

    text-align: justify;
}

.contact-us-form-container{
    width: 90%;
    
    margin: 2rem auto;
}
.contact-us-form{
    padding: .5rem 0;

    border-radius: .5rem;

    background-color: #3C8AFF;
    text-align: center;
}
.contact-us-form-input{
    width: 95%;
    padding: 0.5rem 1%;
    margin: .5rem auto;

    background: none;
    border: none;
    border-bottom: 2px solid #59AFFF;

    font-family: inherit;
    font-weight: bold;
    font-size: 1rem;
    color: #FFFFFF;
}
.contact-us-form-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #FFFFFF;
    opacity: .75; /* Firefox */
}
.contact-us-form-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #FFFFFF;
}
.contact-us-form-input::-ms-input-placeholder { /* Microsoft Edge */
    color: #FFFFFF;
}
.contact-us-form > textarea{
    height: 8rem;
    resize: none;
}
.contact-us-form > textarea::placeholder {
    font-size: 16px;
    /* line-height: calc((128px * 2) - 16px) */
}
.contact-us-form-button{
    width: 97%;
    margin: .5rem auto 3rem auto;

    text-align: left;
}
.contact-us-form-submit-button{
    min-width: 5.5rem;
    padding: 0.5rem 1rem;
    border: none;
    background-color: #4361FF;
    border-radius: 0.5rem;
    color: #FFFFFF;
    font-weight: bold;
    cursor: pointer;
}
.contact-us-form-submit-button:hover{
    background-color: #FFFFFF;
    color: #4361FF;
    transition: 1s;
}

/* MDN */
@media (max-width: 850px) {
    .contact-us-top-left{
        width: 80%;
        height: 20rem;
    }
    .contact-us-top-right{
        width: 80%;
    }
}
@media (max-width: 768px) {
    .contact-us-title-before{
        width: 10%;
        order: 0;
    }
    .contact-us-title-bar{
        width: 90%;
        order: 1;
    }
    .contact-us-title{
        width: 80%;
        margin-top: 1rem;
        margin-left: 10%;
        order: 2;
    }
    .contact-us-title-cell{
        text-align: left;
    }
}
@media (max-width: 600px) {
    .contact-us-form-container{
        width: 95%;
    }
}